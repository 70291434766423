import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { ErrorComponent } from './pages/error/error.component';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'pages', loadChildren: './pages/pages.module#PagesModule' },
    { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
    { path: 'reroute', loadChildren: './pages/reroute/reroute.module#RerouteModule' },
    //{ path: 'backend', loadChildren: 'app/pages/cedere-backend/cedere-back-end.module#CedereBackEndModule' },
    { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
  { path: '**', component: ErrorComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
   // useHash: true
});